nav {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    width: 100%;
    height: 100px;
    z-index: 2;
    transition: top 0.3s;
    background-color: $bg-blue-100;
    padding-left: 3rem;
    padding-right: 3rem;

    .nav__menu {
        display: flex;
        flex-direction: column;

        @media all and (min-width: 960px) {
            flex-direction: row;
        }

        &.nav__menu--open {
            position: absolute;
            top: 0;
            z-index: 999;
            background-color: $bg-blue-100;
            width: 80%;
            text-align: center;

            .nav__menu__toggle {
                padding-top: 2rem;
                align-self: flex-end;
            }

        }
    }

    li:not(.nav__logo, .nav__menu__toggle) {
        list-style: none;
        margin-left: 1rem;
        margin-right: 1rem;
        display: none;

        &.nav--visible {
            margin-top: 1rem;
            margin-bottom: 1rem;
            display: block;
        }

        @media all and (min-width: 960px) {
            display: block;
        }
    }

    .nav__menu__toggle {
        padding-right: 2rem;
        display: block;

        @media all and (min-width: 960px) {
            display: none;
        }
    }

    .nav__logo {
        display: block;

        .nav__logo__img {
            max-width: 75%;
            display: inline;
        }
    }

    a {
        text-decoration: none;
        color: $p-100;

        &:hover {
            color: $accent;
        }
    }
}