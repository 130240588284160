button {
    border-color: $p-100;
    color: $p-100;
    border-radius: 3px;
    background-color: transparent;
    border-style:solid;
    width: 10rem;
    height: 3rem;
    cursor: pointer;
    display: block;
    position: relative;

    &.btn--icon {
        border: none;
        width: fit-content;
        height: fit-content;
    }

    &.btn--hidden {
        display: none;
    }

    &.btn--centered {
        margin: auto;
    }

    &:hover {
        a {
            color: $accent;
        }
        
        color: $accent;
        border-color: $accent;
    }
}