.carousel {
    margin: auto;
    margin-bottom: 2rem;
    text-align: center;

    .carousel__date-switcher {
        margin-bottom: $mg-xsmall;
    }

    button.carousel__prev__btn, button.carousel__next__btn {
        display: inline;

        &.btn--hide{
            display: none;
        }
    }

    .animate {
        &.carousel__next__btn {
            animation: fadeIn;
            animation-duration: 1s;
            animation-fill-mode: both;
        }
    }

    ul {
        * {
            margin-top: 1.5rem;
        }

        &.carousel__prev__btn {
            animation: fadeIn 2s both;
        }

        
    }
}