.side-content {
    display: none;

    .side-content__line {
        opacity: 0.4;
    }

    a {
        * {
            color: $p-100;
            opacity: 0.4;

            &:hover {
                opacity: 1;
            }
        }
    }

    @media all and (min-width: 960px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 10%;
        height: 100%;
        position: fixed;
        bottom: 0;
        right: 0;

        .side-content__line {
            height: 10%;
            width: 1px;
            background-color: $p-100;
        }

        .side-content__text {
            font-size: 14px;
            margin-bottom: 70px;
            transform: rotate(90deg);
        }
    }
}