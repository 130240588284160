.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    text-align: center;

    .footer__text {
        color: $p-40;
    }

    .footer__icons {
        &>* {
            color: $p-40;
            margin-right: $mg-xsmall;
            margin-left: $mg-xsmall;
            margin-top: $mg-xsmall;
        }
    }
}