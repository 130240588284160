body {
  font-size: calc(14px + (19 - 14) * ((100vw - 300px) / (1600 - 300)));
}

ul {
    font-size: calc(14px + (19 - 14) * ((100vw - 300px) / (1600 - 300)));
}

small {
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    color: $p-100;
}

a {
    text-decoration: none;
    color: $p-100;
}

h1 {
    font-size: clamp(40px, 8vw, 80px);
    color: $header;
}

h2 {
    font-size: clamp(20px, 4vw, 40px);
    color: $p-100;
}

h3 {
    font-size: clamp(16px, 3vw, 30px);
    color: $p-100;
}

h4 {
    font-size: clamp(16px, 2vw, 22px);
    color: $p-100;
}

p.card-body {
    font-size: clamp(14px, 1vw, 16px);
    line-height: normal;
    color: $p-100;
}

p{
    color: $p-100;
    line-height: 2rem;
}

ul,li,span {
    color: $p-100;
}

.text--accent {
    color: $accent
}