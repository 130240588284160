.img__wrapper {
    position: relative;
    float: left;

    img {
        height: 60%;
        border-radius: 3px;
        opacity: 1;
    }

    &.cursor--pointer{
        cursor: pointer;
    }

    &.accent--line:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 60%;
        border: 3px solid #CE5E82;
        top: 9%;
        right: -9%;
        z-index: -1;
        border-radius: 3px;
        
        @media all and (min-width: 960px) {
            height: 100%;
        }
    }

    @media all and (min-width: 960px) {
        width: auto;

        img {

            &.lg {
                max-height: 75%;
                max-width: 75%;
            }

            &.md {
                max-height: 250px;
                max-width: 250px;
            }
        }

        &::after {
            top: 1rem;
            right: -1rem;
        }
    }
}