.gallery__container {
    &> :first-child {
        margin-bottom: 6rem;
    }
}

.gallery__small-projects-title {
    text-align: center;

    @media all and (min-width: 960px) {
        text-align: left;
    }
}

.gallery__small-projects {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    margin-bottom: $mg-small;

    .gallery__small-projects__card {
        flex-basis: 100%;
        text-align: left;
        margin-top: $mg-small;
        margin-bottom: $mg-medium;
        transition: transform 0.2s ease-in-out;

        .gallery__small-projects__card__icon {
            float: right;
        }

        &:hover {
            transform: translateY(-10px);
        }

        &>* {
            margin-bottom: $mg-xsmall;
        }

        @media all and (min-width: 960px) {
            flex-basis: 30%;
        }
    }

}