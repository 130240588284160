.contact__container {

    .contact__container__card {
        border-radius: 3px;
        background-color: $bg-accent;
        padding: 1rem;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $mg-medium;

        @media all and (min-width: 960px) {
            width: 50%;
        }
    }

    .contact__container__button {
        margin: auto;
    }
}