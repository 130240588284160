.about {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;

    @media all and (min-width: 960px) {
        grid-template-columns: 3fr 2fr;
    }

    .about__image {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    ul {
        width: fit-content;
        columns: 2;
        column-gap: 5rem;
        margin: auto;
        list-style: "•";
        text-align: center;

        li>span {
            margin-left: 1rem;
        }
    }

    p {
        text-align: center;
        margin-bottom: 1rem;

        @media all and (min-width: 960px) {
            text-align: left;
        }
    }

    @media all and (min-width: 960px) {
        flex-direction: row;
        align-items: flex-start;
        margin: auto;

        .about-content__links {
            display: none;
        }
    }
}