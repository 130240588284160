@import './reset.scss';
@import 'animate.css';
@import './vars';
@import './typography';
@import './button';
@import './image';
@import './separator';
@import './carousel';
@import './navbar';
@import './landing';
@import './about';
@import './gallery-item';
@import './gallery';
@import './contact-me';
@import './side-content';
@import './footer';

html {
  background-color: $bg-blue-100;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $p-40;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-track {
    background: $bg-accent;
  }
}

body {
  font-family: 'Maven Pro', 'Exo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app__content>* {
  width: 70%;
  margin: auto;
}

.flow > *  {
  margin-bottom: 9rem;
  
  @media all and (min-width: 1000px) {
    margin-bottom: 13rem;
  }
}

ul {
  list-style: "•";
  list-style-position: inside;
  color: $accent;
}

.slide {
  display: flex;
  flex-direction: column;
  scroll-margin: 100px;

  &.slide--lg {
    min-height: 90vh;
    justify-content: center;

    &#gallery {
      justify-content: flex-start;
      min-height: auto;
    }
  }

  &.slide--md {
    justify-content: flex-start;
  }

  &.slide--sm {
    justify-content: flex-start;
  }

  .card {
    border-radius: 3px;
    background-color: $bg-accent;
    padding: 2rem;
  }
}