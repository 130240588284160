.separator {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    &.separator--centered{
        justify-content: center;
    }

    &.separator--right{
        flex-direction: row-reverse;

        :first-child {
            margin-left: $mg-small;
        }
    }

    &.separator--left{
        :first-child {
            margin-right: $mg-small;
        }
    }

    .separator__title--center{
        margin-left: $mg-small;
        margin-right: $mg-small;
        text-align: center;
    }

    span.separator__line {
        width: 20rem;
        height: 1px;
        background-color: $accent;
        display: inline-block;
        position: relative;
    
        &::after {
            content: '';
            position: absolute;
            background-color: $accent;
            height: 10px;
            width: 10px;
            border-radius: 5px;
            top: -5px;
        }
        
        &.separator--right{
            &::after{
                left: 0
            }
        }

        &.separator--left{
            &::after{
                right: 0
            }
        }

        &.separator--half {
            width: 20%;
        }
    }
}

