.gallery-item {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;

    .gallery-item__image {
        position: relative;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 8;
        display: none;

        @media all and (min-width: 960px) {
            display: block;
        }

        &:hover {
            z-index: 2;
        }

        img {
            opacity: 0.3;
            transition: opacity 0.5s ease-in;

            &:hover {
                opacity: 1;
            }
        }
    }

    .gallery-item__description {
        z-index: 1;
        position: relative;
        text-align: center;
        grid-column-start: 1;
        grid-column-end: -1;
        grid-row-start: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &>* {
            margin-bottom: $mg-small;
        }

        @media all and (min-width: 960px) {
            text-align: right;
            grid-column-start: 6;
        }

        .gallery-item__card {
            text-align: center;

            @media all and (min-width: 960px) {
                text-align: right;
            }

        }

        .gallery-item__tech {
            &>* {
                margin-right: $mg-xsmall;
            }
        }

        .gallery-item__links {
            display: block;

            button {
                margin: auto;
            }

            @media all and (min-width: 960px) {
                display: none;
            }
        }
    }
}