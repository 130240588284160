.landing__container{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 75%;
    align-items: flex-start;
    text-align: left;

    h1 {
        &::after {
            content: '.';
            color: $accent;
        }

        margin-bottom: $mg-medium;
    }

    h2 {
        color: $p-40;
    }

    p:not(:nth-of-type(1)) {
        text-align: justify;
        margin-bottom: $mg-large;
    }

    button {
        align-self: flex-start;
        margin-top: $mg-large;
    }
}

