// colors
$bg-blue-100: #091323;
$bg-blue-90: rgba(9,19,35,90%);
$bg-accent: rgba(33, 42, 56);
$header: #DBDBEA;
$p-100: #b9b8d6;
$p-40: rgba(185,184,214,0.4);
$accent: #CE5E82;

// margins
$mg-xsmall: 0.5rem;
$mg-small: 1rem;
$mg-medium: 1.5rem;
$mg-large: 3rem;

// delays
.animate__delay-1 {
    animation-delay: .2s;
}

.animate__delay-2 {
    animation-delay: .4s;
}

.animate__delay-3 {
    animation-delay: .6s;
}

.animate__delay-4 {
    animation-delay: .8s;
}
